@import '../node_modules/antd/dist/antd.less';
@body-background: #f2f2f2;
@primary-color: #51b148;
@error-color: #f44336;
@secondary-color: #2660a4;
@layout-body-background: @body-background;

@layout-header-background: #51b148;

@layout-header-height: 48px;

@border-radius-base: 8px;

// @border-radius-base: 8px;
@success-color: @primary-color;
.ant-select-selection--single .ant-select-selection__rendered {
  margin-right: 32px;
}

.ant-card {
  border-radius: @border-radius-base;
  box-shadow: 5px 8px 24px 5px rgba(208, 216, 243, 0.6);
}

*,
*:before,
*:after {
  box-sizing: inherit;
}
body {
  font-family: 'Helvetica', 'Arial', sans-serif;
}
hr {
  width: 100%;
}

#root {
  width: 100%;
  height: 100%;
}
.ant-switch-checked {
  background-color: #51b148;
}
.userTable {
  vertical-align: baseline;
}
